<template>
    <div class="options-container" :id="'container_' + container.id">
        <div class="sections">
            <div class="info">
               <img :src="container.img" alt="" /> 
               <h3>{{container.title}}</h3>
               <p>{{container.text}} <br> <button v-on:mouseover="openTooltip($event)" v-on:mouseleave="closeTooltip($event)" v-on:focus="openTooltip($event)" v-on:blur="closeTooltip($event)" v-on:click.prevent data-tooltip="true" class="tool">{{container.tooltip_trigger}}</button></p>
               <TooltipComponent :tooltip="container.tooltip" />
            </div>
            <div class="options">
                <h3 class="option-title">{{container.tooltip.display_title}}</h3>
                <OptionComponent 
                    v-for="option in container.options"
                    :option="option"
                    v-on:active-state-changed="updateActiveState(option.id)" 
                    :container_id="container.id"
                    :key="option.id" 
                    />    
            </div>
        </div>
    </div>
</template>


<script>
import OptionComponent from "./Option.vue";
import TooltipComponent from "./Tooltip.vue";

export default {
  name: "ContainerComponent",
  props: {
    container: Object
  },
  methods: {
    updateActiveState(optionID) {
      this.$emit("active-state-changed", optionID);
    },
    openTooltip($event) {
        $event.preventDefault();
        this.$set(this.container.tooltip, "show", true);
    },
    closeTooltip($event) {
        $event.preventDefault();
        this.$set(this.container.tooltip, "show", false);
    }
  },
  components: {
    /* eslint-disable vue/no-unused-components */
    TooltipComponent,
    OptionComponent
  },
};
</script>

<style lang="scss">
.options-container {
  margin: 20px 0;
  padding: 25px;
  background-color: #f5f5f5;
  @media only screen and (max-width: 1315px) {
    padding: 50px 25px;
  }
  @media only screen and (max-width: 767px) {
    padding: 16px;
  }
  @media only screen and (max-width: 768px) {
    padding: 32px 16px;
    flex-direction: column;
  }
  .sections {
    min-height: 184px;
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    @media print {
      justify-content: center;
      align-items: center;
    }
    .info {
      align-self: center;
      flex: 0 1 15%;
      position: relative;
      img {
        height: auto;
      }
      h3 {
        margin: 5px;
        font-size: 28px;
        font-family: 'Roboto', 'Open Sans', Helvetica, Tahoma, sans-serif;
        font-weight: 300;

        .ws-variable-fonts & {
          font-family: "WS Variable", "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
        }
      }
      p {
        button {
          color: #0072BC;
          &:hover {
            text-decoration: none;
          }
          &:focus {
            outline: 2px dotted;
          }
        }
      }
      @media only screen and (max-width: 767px) {
          flex: 1;
          width: 100%;
          img {
            width: 50px;
          }
          h3 {
            font-size: 32px;
            margin: 0 0 10px;
          }
          p {
            font-size: 18px;
            margin: 0 0 24px;
          }
      }
    }
    .options {
      display: flex;
      justify-content: flex-start;
      flex: 1 0 80%;
      flex-wrap: wrap;
      .option-title {
        display: none;
      }
      @media only screen and (max-width: 1350px) {
        .option-wrapper {
          margin: 25px 45px;
        }
      }
      @media only screen and (max-width: 1015px) {
        .option-wrapper {
          margin: 25px 40px;
        }
      }
      @media only screen and (max-width: 992px) {
        justify-content: center;
        flex: 1;
      }

      @media only screen and (max-width: 845px) {
        margin: 25px 25px;
      }

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        margin: 0 25px;
        .option-title {
          display: block;
        }
        .option-wrapper {
          margin: 5px 15px;
        }
      }
      @media only screen and (max-width: 480px) {
        flex-direction: column;
        margin: 0;
        .option-wrapper {
          margin: 5px 0;
        }
      }
    }
  }
}
</style>