<template>
  <div class="results">
    <div class="flex">
      <div id="chart_container">
        <div id="chart"></div>
      </div>
      <div class="info">
        <h2>
          Estimated Success Rate:
          <span>{{data[1].y}}%</span>
        </h2>
        <h2>
          Estimated Failure Rate:
          <span>{{data[0].y}}%</span>
        </h2>
        <p>
          This means you have an estimated {{data[1].y}}% chance money will last {{selected_year}} years,
          <br>
          but a {{data[0].y}}% chance it won’t.
        </p>
        <div class="ws-wheel-tooltip-container">
          <button
            v-on:mouseleave="closeTooltip($event)"
            v-on:mouseover="openTooltip($event)"
            v-on:blur="closeTooltip($event)"
            v-on:focus="openTooltip($event)"
            v-on:click.prevent
            data-tooltip="true">
              Learn More
          </button>
          <TooltipComponent :tooltip="this.tooltip" />
        </div>
        <div class="buttons">
          <button v-on:click="openPrint()">PRINT/SAVE PDF</button>
          <button v-on:click="openEmailClient()">EMAIL</button>
        </div>
        <h3>Ask about strategies that can help improve the chance of retirement income success.</h3>
      </div>
    </div>
  </div>
</template>

<script>
// import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import TooltipComponent from "./Tooltip";

export default {
  props: ["activeStates", "data"],
  name: "ResultComponent",
  data() {
    return {
      chart: undefined,
      tooltip: {
        title: "Success Rate",
        text:
          "The likelihood your money will last in retirement based on your investment and withdrawal factors.",
        show: false,
        styles: {
          top: "35px"
        }
      },
      chartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          renderTo: "chart"
        },
        credits: {
          enabled: true
        },
        title: {
          text: ""
        },
        exporting: {
          enabled: false
        },
        labels: {},
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
              distance: -80,
              crop: false,
              className: "label",
              overflow: "allow",
              style: {
                fontSize: "18px",
                textOutline: "none"
              },
              filter: {
                property: "percentage",
                operator: ">",
                value: 10
              },
              y: -25
            }
          }
        },
        series: [
          {
            name: "Withdrawal Percentages",
            type: "pie",
            data: [
              {
                name: "Failure",
                color: "#767676",
                selected: false,
                sliced: false,
                y: this.data[0].y
              },
              {
                name: "Success",
                color: "#2466A1",
                selected: false,
                sliced: false,
                y: this.data[1].y
              }
            ]
          }
        ]
      }
    };
  },

  methods: {
    openPrint() {
      window.print();
    },
    openEmailClient() {
        window.location.href = "mailto:?subject=Retirement%20Income%20Calculator%20from%20W%26S%20Financial%20Group%20Distributors&body=I%20thought%20you%20might%20appreciate%20this%20link%20to%20a%20Retirement%20Income%20Calculator%20-%20" + window.location.origin + window.location.pathname + ".%20It%27s%20an%20interactive%20tool%20that%20can%20help%20you%20get%20a%20snapshot%20of%20whether%20retirement%20income%20will%20last%20based%20on%20certain%20factors.%20It%27s%20easy.%20It%20only%20takes%20a%20minute.%20And%20it%27s%20adjustable%20so%20you%20can%20try%20different%20scenarios.%20You%20may%20want%20to%20give%20it%20a%20try.";
    },
    openTooltip($event) {
        $event.preventDefault();
        this.$set(this.tooltip, "show", true);
    },
    closeTooltip($event) {
        $event.preventDefault();
        this.$set(this.tooltip, "show", false);
    },
    render() {
      this.chart = new Highcharts.Chart(this.chartOptions);
    }
  },
  mounted() {
    this.render();
  },
  computed: {
    selected_year() {
      return this.activeStates[2].numericalValue;
    }
  },
  watch: {
    data(newValue) {
      this.chartOptions.series = [{ data: newValue }];
      this.chart.series[0].setData(this.data, true);
    }
  },
  components: {
    TooltipComponent
  }
};
</script>

<style lang="scss">
.results {
  .flex {
    display: flex;
    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }
    @media print {
      flex-direction: column;
    }
    .info {
      text-align: left;
      width: 100%;
      padding: 0 0.5rem;
      @media only screen and (max-width: 1100px) { 
        text-align: center;
      }
      p {
        position: relative;
      }
      .ws-wheel-tooltip-container {
        display: inline-block;
        position: relative;  
        button {
          font-size: 16px;
          color: #0072bc;
          position: relative;
          text-decoration: underline;
          &:focus {
            outline: 2px dotted;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        p {
          br {
            display: none;
          }
        }
      }
      h2 {
        font-family: "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
        font-size: 36px;
        color: #0a5586;
        letter-spacing: 0;
        font-weight: 300;
        padding: 0.5rem 0;
        margin: 0;
        &:nth-of-type(2) {
          margin-bottom: 2rem;
        }
        .ws-variable-fonts & {
            font-family: "WS Variable", "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
        }
        span {
          color: #f06d33;
          font-family: "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
          font-weight: 500;

          .ws-variable-fonts & {
            font-family: "WS Variable", "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
          }
        }
      }
      h3 {
        font-family: "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
        font-size: 28px;
        color: #0a5586;
        letter-spacing: 0;
        font-weight: 300;
        margin-top: 1em;

        .ws-variable-fonts & {
          font-family: "WS Variable", "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
        }
      }
      .buttons {
        display: flex;
        justify-content: flex-start;
        margin-top: 1em;
        @media only screen and (max-width: 1100px) { 
          justify-content: center;
        } 
        @media only screen and (max-width: 575px) {
          flex-direction: column;
          button {
            margin-bottom: 15px;
          }
        }
        button {
          background-color: transparent;
          border: 2px solid #095586;
          color: #095586;
          border-radius: 50px;
          display: inline-block;
          font-family: "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
          font-size: 1.4rem;
          font-weight: bold;
          letter-spacing: .5px;
          line-height: 1.4em;
          min-width: 205px;
          padding: 11px;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          transition: all .15s ease-in-out;
          vertical-align: middle;
          z-index: 0;
          margin-right: 15px;

          &:visited {
            background-color: transparent;
            border: 2px solid #095586;
            color: #095586;
          }

          &:focus {
            border: 2px solid #095586;
            color: #095586;
          }

          &:hover {
            background-color: #095586;
            border: 2px solid #095586;
            color: white;
            transform: scale(1.025);
            text-decoration: none;
          }
          &:active{
            transform: scale(1);
          }
          body:not(.ws-click) &:focus {
            color: #095586;
            background-color: transparent;
            outline: 2px dotted #095586;
            outline-offset: 5px;
            text-decoration: none;
          }
          .ws-variable-fonts & {
            font-family: "WS Variable", "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
          }
        }
        @media print {
          display: none;
        }
      }
    }
    #chart_container {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
  }
}
</style>