<template>
    <transition name="slide-fade">
        <div v-if="tooltip.show" class="ws-wheel-tooltip" :style="tooltip.styles">
            <div class="title">
                <p>{{tooltip.title}}</p>
            </div>
            <p>{{tooltip.text}}</p>
        </div>
    </transition>
</template>

<script>
export default {
  name: "TooltipComponent",
  props: {
    tooltip: Object
  },
  methods: {
    closeTooltip($event) {
      $event.preventDefault();
      this.$emit("close-tooltip", $event);
    }
  }
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.15s ease;
}
.slide-fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

#app .ws-wheel-tooltip {
  background: #0072bc;
  position: absolute;
  padding: 0.5rem 1rem;
  text-align: left;
  width: 200px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  cursor: default!important;
  z-index: 1;
  .title p,
  p {
    margin: 0!important;
    font-size: 14px!important;
    color: white;
  }
  .title {
    margin-bottom: 7px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    button {
      border: none;
      background: none;
      margin-right: -10px;
      cursor: pointer!important;
      &:hover {
        cursor: pointer!important;
      }
      &:focus {
        outline: 0;
      }
    }
  }
  &:after {
    border: 1px solid #0072bc;
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #0072bc;
    border-width: 10px;
    margin-left: -10px;

  }
  &:before {
    border: 1px solid #0072bc;
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(151, 151, 151, 0);
    border-bottom-color: #0072bc;
    border-width: 11px;
    margin-left: -11px;
  }
}
</style>