<template>
  <div id="app">
    <div class="print-wrap">
      <ContainerComponent
        v-for="item in containers"
        :container="item"
        :key="item.id + '_container'"
        v-on:active-state-changed="updateActiveState(item.id, $event)"
        v-on:toogle-tooltip="toggleTooltip(item.id, $event)"
      />
    </div>
    <ResultComponent :data="dataPoints" :activeStates="activeSelected"/>
  </div>
</template>

<script>
import ContainerComponent from "./components/Container.vue";
import ResultComponent from "./components/Result.vue";

export default {
  /* eslint-disable */
  name: "app",
  data() {
    return {
      containers: [
        {
          id: 1,
          img: "/distributionWheel/dist/assets/step1.svg",
          title: "Step 1",
          text: "Select a retirement withdrawal rate.",
          tooltip_trigger: "Learn More",
          tooltip: {
            title: "Withdrawal Rate",
            display_title: "Withdrawal Rate",
            text:
              "The percentage you plan to take from your assets each year for retirement income.",
            show: false
          },
          options: [
            {
              id: "step1-1",
              numericalValue: 2,
              textualValue: "2%",
              label: '2% withdrawal rate',
              text: "Withdrawal Rate",
              isActive: false
            },
            {
              id: "step1-2",
              numericalValue: 3,
              textualValue: "3%",
              label: '3% withdrawal rate',
              text: "Withdrawal Rate",
              isActive: false
            },
            {
              id: "step1-3",
              numericalValue: 4,
              textualValue: "4%",
              label: '4% withdrawal rate',
              text: "Withdrawal Rate",
              isActive: true
            },
            {
              id: "step1-4",
              numericalValue: 5,
              textualValue: "5%",
              label: '5% withdrawal rate',
              text: "Withdrawal Rate",
              isActive: false
            },
            {
              id: "step1-5",
              numericalValue: 6,
              textualValue: "6%",
              label: '6% withdrawal rate',
              text: "Withdrawal Rate",
              isActive: false
            },
            {
              id: "step1-6",
              numericalValue: 7,
              textualValue: "7%",
              label: '7% withdrawal rate',
              text: "Withdrawal Rate",
              isActive: false
            }
          ]
        },
        {
          id: 2,
          img: "/distributionWheel/dist/assets/step2.svg",
          title: "Step 2",
          text: "Select a hypothetical stock/bond allocation (%).",
          tooltip_trigger: "Learn More",
          tooltip: {
            title: "Stock/Bond Allocation",
            display_title: "Stock/Bond Allocation",
            text:
              "The mix of stocks and bonds in your investment portfolio during retirement.",
            show: false
          },
          options: [
            {
              id: "step2-1",
              numericalValue: 2080,
              textualValue: "20/80",
              label: '20/80 stocks/bonds',
              text: "Stocks/Bonds",
              isActive: false
            },
            {
              id: "step2-2",
              numericalValue: 4060,
              textualValue: "40/60",
              label: '40/60 stocks/bonds',
              text: "Stocks/Bonds",
              isActive: false
            },
            {
              id: "step2-3",
              numericalValue: 6040,
              textualValue: "60/40",
              label: '60/40 stocks/bonds',
              text: "Stocks/Bonds",
              isActive: true
            },
            {
              id: "step2-4",
              numericalValue: 8020,
              textualValue: "80/20",
              label: '80/20 stocks/bonds',
              text: "Stocks/Bonds",
              isActive: false
            },
            {
              id: "step2-5",
              numericalValue: 1000,
              textualValue: "100/0",
              label: '100/0 stocks/bonds',
              text: "Stocks/Bonds",
              isActive: false
            }
          ]
        },
        {
          id: 3,
          img: "/distributionWheel/dist/assets/step3.svg",
          title: "Step 3",
          text: "Select a withdrawal period.",
          tooltip_trigger: "Learn More",
          tooltip: {
            title: "Withdrawal Period",
            display_title: "Years",
            text:
              "The number of years you expect to live into retirement and need retirement income.",
            show: false
          },
          options: [
            {
              id: "step3-1",
              numericalValue: 20,
              textualValue: "20",
              label: '20 Years',
              text: "Years",
              isActive: false
            },
            {
              id: "step3-2",
              numericalValue: 25,
              textualValue: "25",
              label: '25 Years',
              text: "Years",
              isActive: false
            },
            {
              id: "step3-3",
              numericalValue: 30,
              textualValue: "30",
              label: '30 Years',
              text: "Years",
              isActive: true
            },
            {
              id: "step3-4",
              numericalValue: 35,
              textualValue: "35",
              label: '35 Years',
              text: "Years",
              isActive: false
            },
            {
              id: "step3-5",
              numericalValue: 40,
              textualValue: "40",
              label: '40 Years',
              text: "Years",
              isActive: false
            }
          ]
        }
      ],
      dataPoints: [
        {
          name: "Success",
          y: 0
        },
        {
          name: "Failure",
          y: 0
        }
      ],
      dataset: {
        withdrawal_rate_2: {
          stocks_bonds_2080: {
            years_20: "100.0%",
            years_25: "100.0%",
            years_30: "100.0%",
            years_35: "99.8%",
            years_40: "99.1%"
          },
          stocks_bonds_4060: {
            years_20: "100.0%",
            years_25: "100.0%",
            years_30: "99.9%",
            years_35: "99.6%",
            years_40: "99.0%"
          },
          stocks_bonds_6040: {
            years_20: "100.0%",
            years_25: "99.9%",
            years_30: "99.5%",
            years_35: "98.8%",
            years_40: "97.6%"
          },
          stocks_bonds_8020: {
            years_20: "100.0%",
            years_25: "99.4%",
            years_30: "98.3%",
            years_35: "96.5%",
            years_40: "94.8%"
          },
          stocks_bonds_1000: {
            years_20: "99.6%",
            years_25: "97.8%",
            years_30: "95.5%",
            years_35: "93.2%",
            years_40: "91.2%"
          }
        },
        withdrawal_rate_3: {
          stocks_bonds_2080: {
            years_20: "100.0%",
            years_25: "99.6%",
            years_30: "96.1%",
            years_35: "88.7%",
            years_40: "78.6%"
          },
          stocks_bonds_4060: {
            years_20: "100.0%",
            years_25: "99.2%",
            years_30: "95.9%",
            years_35: "90.9%",
            years_40: "84.7%"
          },
          stocks_bonds_6040: {
            years_20: "99.7%",
            years_25: "97.5%",
            years_30: "93.5%",
            years_35: "88.5%",
            years_40: "83.5%"
          },
          stocks_bonds_8020: {
            years_20: "98.3%",
            years_25: "94.6%",
            years_30: "89.5%",
            years_35: "84.8%",
            years_40: "80.0%"
          },
          stocks_bonds_1000: {
            years_20: "95.9%",
            years_25: "90.2%",
            years_30: "84.9%",
            years_35: "79.8%",
            years_40: "75.6%"
          }
        },
        withdrawal_rate_4: {
          stocks_bonds_2080: {
            years_20: "98.8%",
            years_25: "87.1%",
            years_30: "64.7%",
            years_35: "45.6%",
            years_40: "30.7%"
          },
          stocks_bonds_4060: {
            years_20: "98.1%",
            years_25: "88.4%",
            years_30: "73.2%",
            years_35: "58.9%",
            years_40: "48.2%"
          },
          stocks_bonds_6040: {
            years_20: "95.8%",
            years_25: "85.4%",
            years_30: "73.7%",
            years_35: "63.8%",
            years_40: "56.1%"
          },
          stocks_bonds_8020: {
            years_20: "91.9%",
            years_25: "81.8%",
            years_30: "71.5%",
            years_35: "63.8%",
            years_40: "58.2%"
          },
          stocks_bonds_1000: {
            years_20: "87.0%",
            years_25: "77.0%",
            years_30: "68.0%",
            years_35: "62.0%",
            years_40: "57.5%"
          }
        },
        withdrawal_rate_5: {
          stocks_bonds_2080: {
            years_20: "83.7%",
            years_25: "46.7%",
            years_30: "22.1%",
            years_35: "10.6%",
            years_40: "5.6%"
          },
          stocks_bonds_4060: {
            years_20: "84.8%",
            years_25: "57.9%",
            years_30: "37.0%",
            years_35: "25.1%",
            years_40: "17.3%"
          },
          stocks_bonds_6040: {
            years_20: "81.9%",
            years_25: "61.8%",
            years_30: "46.5%",
            years_35: "36.0%",
            years_40: "29.5%"
          },
          stocks_bonds_8020: {
            years_20: "77.9%",
            years_25: "61.6%",
            years_30: "50.2%",
            years_35: "42.4%",
            years_40: "36.5%"
          },
          stocks_bonds_1000: {
            years_20: "73.3%",
            years_25: "60.0%",
            years_30: "50.6%",
            years_35: "44.3%",
            years_40: "40.0%"
          }
        },
        withdrawal_rate_6: {
          stocks_bonds_2080: {
            years_20: "43.7%",
            years_25: "12.8%",
            years_30: "3.7%",
            years_35: "1.4%",
            years_40: "0.5%"
          },
          stocks_bonds_4060: {
            years_20: "53.5%",
            years_25: "25.9%",
            years_30: "12.5%",
            years_35: "6.9%",
            years_40: "4.1%"
          },
          stocks_bonds_6040: {
            years_20: "57.7%",
            years_25: "35.8%",
            years_30: "23.5%",
            years_35: "16.4%",
            years_40: "12.7%"
          },
          stocks_bonds_8020: {
            years_20: "57.8%",
            years_25: "41.0%",
            years_30: "31.3%",
            years_35: "25.0%",
            years_40: "20.7%"
          },
          stocks_bonds_1000: {
            years_20: "56.7%",
            years_25: "43.4%",
            years_30: "34.9%",
            years_35: "29.6%",
            years_40: "26.1%"
          }
        },
        withdrawal_rate_7: {
          stocks_bonds_2080: {
            years_20: "12.4%",
            years_25: "1.8%",
            years_30: "0.3%",
            years_35: "0.1%",
            years_40: "0.0%"
          },
          stocks_bonds_4060: {
            years_20: "24.1%",
            years_25: "7.3%",
            years_30: "2.9%",
            years_35: "1.4%",
            years_40: "0.7%"
          },
          stocks_bonds_6040: {
            years_20: "33.9%",
            years_25: "17.2%",
            years_30: "9.6%",
            years_35: "6.4%",
            years_40: "4.6%"
          },
          stocks_bonds_8020: {
            years_20: "39.6%",
            years_25: "25.2%",
            years_30: "17.3%",
            years_35: "13.1%",
            years_40: "10.9%"
          },
          stocks_bonds_1000: {
            years_20: "41.9%",
            years_25: "29.7%",
            years_30: "22.6%",
            years_35: "18.6%",
            years_40: "15.9%"
          }
        }
      },
      env: process.env.NODE_ENV
    };
  },
  created() {
    this.requestPercentage();
  },
  methods: {
    updateActiveState(containerID, payload) {
        // Find the container in the containers array
        const container = this.containers.find((element) => element.id === containerID);

        if (container) {
            // Loop through the options and update isActive
            container.options.forEach((item) => {
                item.isActive = item.id === payload;
            });
        }
    },
    requestPercentage() {
      let withdrawal_rate =
          "withdrawal_rate_" + this.activeSelected[0].numericalValue,
        stocks_bonds = "stocks_bonds_" + this.activeSelected[1].numericalValue,
        years = "years_" + this.activeSelected[2].numericalValue,
        percentage = this.dataset[withdrawal_rate][stocks_bonds][years],
        success_num = parseFloat(percentage.replace("%", "")),
        failure_num = 100 - parseFloat(percentage.replace("%", ""));

      this.dataPoints = [
        
        {
          name: "Failure",
          color: "#767676",
          selected: false,
          sliced: false,
          y: parseFloat(failure_num.toFixed(1))
        },
        {
          name: "Success",
          color: "#2466A1",
          selected: false,
          sliced: false,
          y: parseFloat(success_num.toFixed(1))
        },
      ];
    },
    toggleTooltip(containerID) {
      this.containers.forEach(container => {
        if (container.id == containerID) {
          var parentContainer = document.getElementById('container_' + container.id);
          var tooltip = parentContainer.getElementsByClassName('tool');
          if (container.tooltip.show == true) {
            this.$set(container.tooltip, "show", false);
            tooltip[0].focus();
          } else {
            this.$set(container.tooltip, "show", true);
          }
        }
      });
    }
  },
  computed: {
    activeSelected() {
      return this.containers.map(item => {
        return item.options.filter(item => item.isActive)[0];
      });
    }
  },
  watch: {
    activeSelected() {
      this.requestPercentage();
    }
  },
  components: {
    ContainerComponent,
    ResultComponent
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700");

#app {
  font-family: "Open Sans, Avenir", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1400px;
  margin: 0 auto;
  p {
    color: #484848;
    &:last-child {
      margin-bottom: 1em;
    }
  }
  @media print {
    max-width: 100%;
    padding: 0px;
    .print-wrap {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      h1 {
        flex-basis: 100%;
      }
      .options-container {
        flex: 1 0 auto;
        margin: 0.5rem;
        padding: 0;
        max-width: 300px;
        justify-content: flex-start;
        .sections {
          // flex-direction: column;
          min-height: 0px;
          .info {
            // flex: 1 1 auto;
            flex: none;
            img {
              display: none;
            }
            h3 {
              display: none;
            }
            p {
              display: none;
            }
          }
          .options {
            div {
              width: 100%;
            }
          }
          .option {
            display: none;
          }
          .printView {
            display: flex;
            p {
              margin: 0;
            }
          }
        }
      }
    }
    .results {
      flex-basis: 100%;
      .info {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 1.75rem;
        h2 {
          padding: .5rem 0;
          font-size: 26px;
          margin-bottom: .5rem;
          &:first-of-type {
            margin-right: .5rem;
          } 
          &:last-of-type {
            margin-left: .5rem;
          }
        }
        p, h3 {
          margin: 0;
          padding: .5rem 0;
          flex-basis: 100%;
        }
      }
    }
  }
}

@media print {
    .ws-nav,
    .ws-section.ws-hero,
    .ws-section--breadcrumbs {
        display: none !important;
    }

    .ws-main-content {
        padding-top: 25px !important;
    }
}
</style>