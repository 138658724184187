<template>
  <div class="option-wrapper">
    <a
      :href="container_anchor"
      v-smooth-scroll="{ duration: 1000, offset: -225,}"
      class="option"
      v-on:click.prevent="updateActiveState($event)"
    >
      <div
        class="circle"
        :class="{active: option.isActive}"
        :value="option.numericalValue"
        :aria-label="option.label"
      >{{option.textualValue}}</div>
      <div v-if="option.isActive" class="title">{{option.text}}</div>
    </a>
    <div class="printView" v-if="option.isActive">
      <p>{{option.text}}: <span>{{option.textualValue}}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionComponent",
  props: {
    option: Object,
    container_id: Number
  },
  methods: {
    updateActiveState(e) {
      e.preventDefault();
      this.$emit("active-state-changed");
    },
    closeTooltip() {
      this.$emit("close-tooltip");
    }
  },
  computed: {
    container_anchor() {
      if (this.container_id == 3) {
        return "#chart_container";
      } else {
        return "#container_" + (this.container_id + 1);
      }
    }
  }
};
</script>

<style lang="scss">
div {
  a.option {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    margin: 0.5rem;
    position: relative;
    @media only screen and (max-width: 768px) {
      border-radius: 0;
      // width: 100%;
      justify-content: center;
    }
    .circle {
      width: 125px;
      height: 125px;
      margin: 0 16px;
      background-color: white;
      border-radius: 75px;
      border: 1px solid #484848;
      font-size: 32px;
      color: #484848;
      letter-spacing: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // transition: all 0.3s ease;
      overflow: hidden;
      backface-visibility: hidden;
      position: relative;
      transform: translateZ(0);
      &:hover {
        background-color: #767676;
        color: #ffffff;
        box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.1);
      }
      @media print {
        display: none;
      }
      &.active {
        background: #0166a6;
        border: 1px solid #0166a6;
        color: #ffffff;
        @media print {
          display: flex;
        }
      }
      @media only screen and (max-width: 767px) {
        border-radius: 0;
        width: 100%;
        max-width: 450px;
        height: 40px;
        margin: 0;
        font-weight: 300;
        font-size: 18px;
      }
    }
    .title {
      position: absolute;
      bottom: -52px;
      left: 50%;
      z-index: 5;
      padding: 0.5rem;
      // background-color: #f5f5f5;
      transform: translate(-50%, 0);
      white-space: nowrap;
      font-size: 28px;
      color: #0166a6;
      letter-spacing: 0;
      text-align: center;
      font-family: "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
      font-weight: 300;
      @media only screen and (max-width: 992px) {
        bottom: -48px;
      }
      @media only screen and (max-width: 767px) { 
        display: none;
      }

      .ws-variable-fonts & {
        font-family: "WS Variable", "Roboto", "Open Sans", Helvetica, Tahoma, sans-serif;
      }
    }
  }
  .printView {
    display: none;
    p {
      font-size: 22px;
      span {
        font-weight: 700;
      }
    }
  }
}
</style>